
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import userModule from '@/store/modules/user/user.module';
import userSettingsSchema from '@/app/config/schema/user/settings';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import SchemaMixin from '@/shared/mixins/schema.mixins';
import profileSchema from '@/modules/guests/schema/profile.schema';
import { Loading, Loader } from '@/modules/auth/store/auth.service';
import { Auth } from '@/store/modules/auth.module';
import guestProfileSchema from '@/modules/guests/schema/guest-profile.schema';
import { UserDto } from '@/app/models/user/user.dto';
import service from '@/services/app-http-client';
import isEmpty from '@/app/utils/object.utils';
import { ITenantDto } from '@/app/entities';
import Routes from '@/shared/constants/routes';
import organisationSchema from '@/modules/organisation/schema/organisation.schema';
import { buildAbilityFor } from '@/plugins/casl/ability';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({})
export default class UserProfile extends mixins(SchemaMixin) {
  @Ref('acc') readonly acc: FormulateForm;
  profileSchema = profileSchema;

  guestProfileSchema = guestProfileSchema;

  userSettingsSchema = userSettingsSchema;

  organisationSchema = organisationSchema;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  tenant: ITenantDto = {} as ITenantDto;

  loading = true;

  profile: Partial<UserDto> = {
    settings: { pushNotifications: 3 },
  };

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  async created() {
    Object.assign(this.profile, userModule.user);
    if (!isEmpty(tenantModule.tenant)) {
      if (Object.keys(tenantModule.tenant).length !== 0) {
        await tenantModule.getTenant();
        this.loading = false;
        this.tenant = tenantModule.tenant as ITenantDto;
      }
    }
  }

  async resetPassword(): Promise<void> {
    await this.$msal.memberPasswordRedirect();
  }

  @Loading
  async saveProfile(): Promise<void> {
    userModule.setUser(this.profile);
    await userModule.updateGuestUser();
    this.$swal('Updated!', 'Your profile details have been updated.', 'success');
  }

  async deleteAccount() {
    const result = await this.$swal({
      title: 'Delete Account',
      text: 'Are you sure you want to delete your account? This will remove all data from the system along with all current bookings and members.',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    });

    if (result.isConfirmed) {
      const res = await service.delete('user', { params: { id: tenantModule.tenant.id } });
    }
  }

  @Loading
  async handle(): Promise<any> {
    const validationCheck = this.acc.formSubmitted();
    if (validationCheck) {
      return this.updateTenant();
    }
    return validationCheck;
  }

  @Loader('Updating your account details')
  async updateTenant(): Promise<any> {
    const payload = this.tenant;
    payload.phone = this.getHashMap(this.tenant.phone);

    if (this.tenant.id) {
      await tenantModule.updateTenant(payload);
      this.tenant = tenantModule.tenant as ITenantDto;
      this.$swal('Updated!', 'Your account details have been updated.', 'success');
      return true;
    }
    await tenantModule.createTenant(payload);

    buildAbilityFor(userModule.member[0].role.ability as any);
    this.$ability.update(userModule.member[0].role.ability as any);

    return this.$router.push(Routes.ORGANISATION_DASHBOARD);
  }
}
