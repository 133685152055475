export default [
  {
    component: 'div',
    class: 'grid grid-cols-6 gap-6',
    children: [
      {
        type: 'radio',
        name: 'emailNotifications',
        label: 'Email notifications',
        'outer-class': 'col-span-6 sm:col-span-3 flex-wrap',

        options: { 3: 'All', 2: 'Bookings', 1: 'Check in', 0: 'none' },
      },
      {
        type: 'radio',
        name: 'smsNotifications',
        label: 'SMS notifications',
        'outer-class': 'col-span-6 sm:col-span-3 flex-wrap',

        options: { 3: 'All', 2: 'Bookings', 1: 'Check in', 0: 'none' },
      },
    ],
  },
];
