export default [
  {
    component: 'div',
    class: 'form-wrap',
    children: [
      // {
      //   type: 'image',
      //   name: 'file',
      //   // 'upload-url': '/your/upload/directory',
      //   label: 'Select an image to upload',
      //   // placeholder: 'Accommodation name',
      //   // help: 'Select a png, jpg or gif to upload.',
      //   validation: 'required',
      //   'keep-model-data': true,
      //   'upload-behavior': 'delayed',
      //   value: {},

      //   // validation: 'mime:image/jpeg,image/png,image/gif',
      //   // 'validation-name': 'Logo',
      //   '@file-upload-complete': 'upload-complete',
      // },
      {
        type: 'text',
        name: 'firstName',
        label: 'First name',
        validation: 'required',
        'validation-name': 'Name',
      },
      {
        type: 'text',
        name: 'lastName',
        label: 'Last name',
        validation: 'required',
        'validation-name': 'Last name',
      },
      {
        type: 'email',
        name: 'email',
        label: 'Email',
        validation: 'required',
        'validation-name': 'Email',
      },
    ],
  },
];
